import "./App.css";
import video from "./video.mp4";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </header>
    </div>
  );
}

export default App;
